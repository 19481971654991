import { assert } from '@ember/debug';
import { on } from '@ember/object/evented';
import { bind } from '@ember/runloop';
import { reads, equal, notEmpty, and, not, or } from '@ember/object/computed';
import { computed } from '@ember/object';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import avatarUtils from '@intercom/pulse/lib/avatar-utils';
import ICBaseComponent from './ic-base-component';

export default ICBaseComponent.extend({
  tagName: 'span',
  componentClasses: computed(
    'options.contained',
    'options.withText',
    'avatarData.deleted',
    'avatarSizeClass',
    'avatarData.isTeam',
    function () {
      let classes = ['avatar', this.get('avatarSizeClass')];
      if (this.get('options.contained')) {
        classes.push('o__contained');
      }
      if (this.get('options.withText')) {
        classes.push('o__with-text');
      }
      if (this.get('avatarData.deleted')) {
        classes.push('o__for-deleted-object');
      }
      if (this.get('avatarData.isTeam')) {
        classes.push('o__for-team');
      }
      return classes.join(' ');
    },
  ),
  onBackgroundColorClass: computed('options.onBackgroundColor', function () {
    if (this.get('options.onBackgroundColor') !== undefined) {
      return `o__on-background-palette-${this.get('options.onBackgroundColor')}`;
    } else {
      return '';
    }
  }),
  nameOrEmail: or('model.alias_name', 'model.alias_email', 'model.nameOrEmail', 'model.name'),
  avatarMediaClasses: computed('avatarData.isTeam', 'avatarData.avatarShape', function () {
    let classes = ['avatar__media'];
    if (this.get('avatarData.isTeam')) {
      classes.push('o__for-team');
    }
    let shapeClass = avatarUtils.avatarShapeClass(this.get('avatarData.avatarShape'));
    if (shapeClass) {
      classes.push(shapeClass);
    }
    return classes.join(' ');
  }),
  unformattedAvatarData: reads('model.avatarData'),
  avatarData: computed('unformattedAvatarData', 'options', function () {
    return avatarUtils.checkAvatarData(this.get('unformattedAvatarData'), this.get('options'));
  }),
  options: computed(
    'size',
    'contained',
    'withText',
    'canAnimate',
    'onBackgroundColor',
    'displayAdminBadge',
    function () {
      return avatarUtils.formatOptions(
        this.getProperties(
          'size',
          'contained',
          'withText',
          'canAnimate',
          'onBackgroundColor',
          'displayAdminBadge',
        ),
      );
    },
  ),
  avatarTypeToRender: computed('avatarData', 'options', function () {
    return avatarUtils.checkAvatarTypeToRender(this.get('avatarData'), this.get('options'));
  }),
  avatarTypeToRenderIsImage: equal('avatarTypeToRender', 'image'),
  avatarTypeToRenderIsText: equal('avatarTypeToRender', 'text'),
  avatarTypeToRenderIsCompany: equal('avatarTypeToRender', 'company'),
  hasInitials: notEmpty('avatarData.initials'),
  shouldDisplayColorAvatar: or('avatarTypeToRenderIsText', 'avatarTypeToRenderIsCompany'),
  shouldDisplayOriginalImageAvatar: and('avatarTypeToRenderIsImage', 'imageLoadingHasNotFailed'),
  backgroundColorStyle: computed('avatarData.color', function () {
    let color = avatarUtils.getAvatarColor(this.get('avatarData'));
    if (typeof color === 'string' && color.startsWith('var(')) {
      return sanitizeHtml(`background-color: ${color};`);
    }
    return sanitizeHtml(`background-color: #${color};`);
  }),
  avatarFallbackUrl: computed('avatarData', 'options', function () {
    return avatarUtils.avatarFallbackUrl(this.get('avatarData'), this.get('options'));
  }),
  avatarSizeClass: computed('options.size', function () {
    return `o__${this.get('options.size')}`;
  }),
  avatarIconPath: computed('options.size', function () {
    return avatarUtils.avatarIconPath(this.get('options.size'));
  }),
  formattedInitials: computed('avatarData.initials', function () {
    return avatarUtils.formatInitials(this.get('avatarData.initials'));
  }),
  shouldRenderAvatarBadge: computed('avatarData', 'options', function () {
    return avatarUtils.shouldRenderAvatarBadge(
      this.get('avatarData'),
      this.get('options.displayAdminBadge'),
    );
  }),
  shouldDisplayAvatarBadge: computed('avatarData', function () {
    return this.get('avatarData.showAsActive') || this.get('avatarData.showAsAway');
  }),
  avatarBadgeColourClass: computed('avatarData', function () {
    if (this.get('avatarData.showAsAway')) {
      return 'o__away';
    } else if (this.get('avatarData.showAsActive')) {
      return 'o__active';
    }
    return '';
  }),
  imageLoadingHasFailed: false,
  imageLoadingHasNotFailed: not('imageLoadingHasFailed'),

  didInsertElement() {
    this._super(...arguments);
    this._imageCheck();
  },
  didReceiveAttrs() {
    this._super(...arguments);
    this._imageCheck();
  },
  _imageCheck() {
    if (this.get('avatarTypeToRenderIsImage')) {
      let currentAvatarDataUrl = this.get('avatarData.url');
      if (this.get('lastAvatarUrl') !== this.get('avatarData.url')) {
        this.set('lastAvatarUrl', currentAvatarDataUrl);
        this.set('imageLoadingHasFailed', false);
        this._makeSureImageHasLoaded();
      }
    }
  },
  _makeSureImageHasLoaded() {
    let img = new Image();
    img.onload = function () {
      if (this.naturalHeight + this.naturalWidth === 0) {
        return this.onerror();
      }
      img = null;
    };
    img.onerror = bind(this, this._imageHasFailedToLoad);
    img.src = this.get('avatarData.url');
  },
  _imageHasFailedToLoad() {
    if (!this.isDestroying) {
      this.set('imageLoadingHasFailed', true);
    }
  },
  makeSureDirectIsNotUsed: on('init', function () {
    if (typeof this.get('direct') !== 'undefined') {
      assert(
        'ic-avatar-component: Do not use direct for binding here. Use model to refer to object whose avatar you want to use.',
      );
    }
  }),
});
